import { IEndpointCall } from "@/types/definitions/apiCalls";
import API from "./api";
import { LeadContactSubmitData, LeadPriceSubmitData, LeadPriceCompareSubmitResponse, LeadSubmitData, AvailableTariffsSubmitData, AvailableTariffsSubmitResponse, AvailableComersResponse, AvailableComersResponseAPI, AvailableTariffsSubmitResponseAPI } from "@/types/models/services/leads";
import { ILanguage } from "@gisce/oficina-virtual-components";

export enum EndpointVersion {
  V1 = "v1",
  // V2 = "v2"
}

export enum EndpointName {
  Compare = '/price-compare/',
  CompareConfirm = '/price-compare/confirm',
  LeadComers = '/leads/comers',
  Tariffs = '/tariff'
}

interface ISendLeadCompareParams extends IEndpointCall {
  cups: string;
  data: LeadPriceSubmitData | LeadContactSubmitData;
}

const sendLeadCompare = ({
  cups,
  data,
  endpoint = {
    name: EndpointName.Compare,
    version: EndpointVersion.V1
  }
}: ISendLeadCompareParams) => {
  return new Promise<LeadPriceCompareSubmitResponse>(async (resolve, reject) => {
    API.post<LeadPriceCompareSubmitResponse, LeadSubmitData>(
      `${endpoint.version}${endpoint.name}`,
      {
        cups,
        ...data
      }
    )
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  })
}

interface IFetchAvaiableTariffsParams extends IEndpointCall {
  data: AvailableTariffsSubmitData;
}

const fetchAvailableAccessTariffs = ({
  data,
  endpoint = {
    name: EndpointName.Tariffs,
    version: EndpointVersion.V1
  }
}: IFetchAvaiableTariffsParams) => {
  return new Promise<AvailableTariffsSubmitResponse>(async (resolve, reject) => {
    API.get<AvailableTariffsSubmitResponseAPI[]>(
      `${endpoint.version}${endpoint.name}`,
      `?power=${data.power}&old=${data.old}` // Parameters
    )
    .then(res => {
      const response:AvailableTariffsSubmitResponse  = {
        tariffs: res.map((tariff) => ({
          name: tariff.name,
          id: tariff.id,
        })),
        status: 200
      };
      resolve(response);
    })
    .catch(err => {
      reject(err);
    })
  })
}

const fetchAvailableComersTariffs = ({
  endpoint = {
    name: EndpointName.LeadComers,
    version: EndpointVersion.V1
  }
}: IEndpointCall, lang: ILanguage) => {
  return new Promise<AvailableComersResponse>(async (resolve, reject) => {
    API.get<AvailableComersResponseAPI[]>(
      `${endpoint.version}${endpoint.name}`,
      '',
      {
        params: {
          lang: lang.value
        }
      }
    )
    .then((res) => {
      const response:AvailableComersResponse  = {
        comers: res.map((comer) => ({
          name: comer.name,
          id: comer.id,
        })),
      };
      resolve(response);
    })
    .catch(err => {
      reject(err);
    })
  })
}


export default {
  sendLeadCompare,
  fetchAvailableAccessTariffs,
  fetchAvailableComersTariffs
};