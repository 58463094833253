import { createFromBaseTheme } from "@/style/baseTheme";
import { createTheme, ThemeOptions } from "@mui/material";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
  palette: {
    primary: {
      main: "#054c32",
    },
    secondary: {
      main: "#fcbd1b"
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
      borderRadius: 15,
      appBarHeightLaptop: 90,
      appBarBackgroundColor: "#eaeeeb",
    }
  }
};

const baseCustomizedTheme = createFromBaseTheme(customConstants);

const customizations = {
  palette: {
    secondary: {
      contrastText: baseCustomizedTheme.palette.primary.main,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: baseCustomizedTheme.palette.primary
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            color: baseCustomizedTheme.palette.primary,
          }
        },
      ],
      defaultProps: {
        color: 'secondary'
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: baseCustomizedTheme.palette.primary.main
        }
      }
    },
  },
  custom: {
    constants: {
      appBarTextColor: baseCustomizedTheme.palette.primary.main,
      defaultButtonColor: "secondary"
    },
    components: {
      reactFormigaTable: {
        style: {
          containerStyle: {
            borderRadius: `${baseCustomizedTheme?.custom?.constants?.borderRadius}px`,
          },
        }
      },
      publicityBanner: {
        stylingProps: {
          titleColor: baseCustomizedTheme.palette.secondary.main
        },
      },
      languageSelect: {
        buttonStyle: {
          color: "primary"
        }
      }
    }
  }
}

const newTheme = createTheme(baseCustomizedTheme, customizations);

export default newTheme;
