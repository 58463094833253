import { IEndpointCall } from "@/types/definitions/apiCalls";
import API from "./api";
import { LeadContactSubmitData, LeadPowerSubmitData, LeadPowerSubmitResponse, LeadSubmitData } from "@/types/models/services/leads";

export enum EndpointVersion {
  V1 = "v1"
}

export enum EndpointName {
  Power = "/leads/potencia"
}

interface ISendLeadPowerParams extends IEndpointCall {
  cups: string;
  data: LeadPowerSubmitData | LeadContactSubmitData;
  endpoint?: { 
    name: EndpointName;
    version: EndpointVersion;
  }
}

const sendLeadPower = ({
  cups,
  data,
  endpoint = {
    name: EndpointName.Power,
    version: EndpointVersion.V1
  }
}: ISendLeadPowerParams) => {
  return new Promise<LeadPowerSubmitResponse>(async (resolve, reject) => {
    API.post<LeadPowerSubmitResponse, LeadSubmitData>(
      `${endpoint.version}${endpoint.name}`,
      {
        cups,
        ...data
      }
    )
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err);
    })
  })
}


export default {
  sendLeadPower
};